import 'bootstrap';
import $ from 'jquery';
import select2 from 'select2';
import dt from 'datatables.net';

$('.add-button').hover(function() {
    $(this).append('<div class="hover-alert">Dodaj do mojej listy</div>');
}, function() {
    $(this).find('div.hover-alert').last().remove();
});

$(document).ready(function() {

    if(window.location.hash.match(/#back/)) {
        window.history.back();
    }

    $('[id^="add"]').click(function (e) {
        const list = $($(this).attr('data-list-selector'));
        let counter = list.data('widget-counter') | list.children().length;

        let newWidget = list.attr('data-prototype');
        newWidget = newWidget.replace(/__name__/g, counter);
        counter++;
        list.data('widget-counter', counter);

        var newElem = $(list.attr('data-widget-inputs')).html(newWidget);
        newElem.appendTo(list);
    });

    $('table').not('.report, .tab').DataTable({
        drawCallback: function(settings) {
          var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
          pagination.toggle(this.api().page.info().pages > 1);
        },
        "language": {
            "url": "https://cdn.datatables.net/plug-ins/1.10.24/i18n/Polish.json"
        }
    });

    $(document).on('shown.bs.tab', 'a[data-toggle="pill"]', function (e) {
        setTimeout(function(){
            var $table = $('table.tab');

            $table.DataTable({
                drawCallback: function(settings) {
                  var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
                  pagination.toggle(this.api().page.info().pages > 1);
                },
                "language": {
                    "url": "https://cdn.datatables.net/plug-ins/1.10.24/i18n/Polish.json"
                }
            });
            $table.removeClass('tab');
        }, 500);
    });

    $('select').select2({
        minimumResultsForSearch: 20,
        language: "pl"
    });

    if (window.location.hash == '#requirements') {
        // $('.nav-pills a[href="#requirements"]').tab('show');
        $('.nav-pills a[href="#requirements"]').click();
    }

    let { hash } = window.location;
    hash && $(`ul.nav a[href="${hash}"]`).tab("show");

    $(".nav-pills a").click(function (e) {
        $(this).tab("show");
        const scrollmem = $("body").scrollTop() || $("html").scrollTop();
        window.location.hash = this.hash;
        $("html,body").scrollTop(scrollmem);
    });

    $('#btnGenerate').click(function(event) {
        $(this).parent().find('textarea').each(function() {
            if ($(this).val().length < 2) {
                $(this).attr('name', null);
            }
        });
    });

    $('#updatedDropdown').on('show.bs.dropdown', function() {
        $.post($(this).find('#dropdownMenuLink').attr('href'), function(data) {
            $('#updatedMenuContent').html(data);
        }).done(function() {
            $('[data-href]').on('click', function() {
                window.location = $(this).data('href');
            });
        });
    });

    $('.confirm').click(function(event) {
        const url = $(this).attr('href');
        const $modal = $('#confirm-modal');

        $modal.find('a#link-confirm').attr('href', url);
    });

    $('.accept_requirement').click(function(event) {
        const url = $(this).data('href');
        const _self = $(this);

        _self.html('Proszę czekać...');

        $.ajax({
            url,
            type: 'get',
            success: function (data) {
                if (data === true) {
                    _self.fadeOut();
                } else {
                    _self.html('Wystąpił błąd');
                }
            }
        });
    });

    $('#btnSaveAndReturn').click(function(){
        $('[name="save-and-return"]').val(true);
        $('#btnEdit').click();
    });

    if ($('[name="cat-user-requirements"]').length) {
        $('.sidebar > .list-group').prepend('<div class="list-group list-group-horizontal"><a class="list-group-item main-category main-category-user-requirements" href="#cat-user-requirements" data-toggle="collapse" role="button" aria-expanded="false"><span class="list-group-label">Własne wymagania</span></a></div>');
    }

    if ($('body > .sidebar').length == 0)
        $('body .wrapper').before($('.sidebar'));

    if (typeof page !== 'undefined') {
        if (page === 'app_report_edit') {
            $('.list-group-item').click(function() {
                $(this).find('i').toggleClass('fas fa-plus fas fa-minus');
                const elTarget = $(`#${$(this).data('target')}`);
                elTarget.toggleClass('collapse');
                window.location.href = $(this).attr('href');
            });
        }
    }

    $('.openTextDialog').click(function(){
        const textContainer = `#${$(this).data('text-container')}`;
        $('#textDialog').data('textContainer', textContainer);
        $('#textDialog textarea').val($(`${textContainer} textarea`).val()); 
    });
    $('#textDialogUpdateBtn').click(function(){
        const textContainer = $('#textDialog').data('textContainer');
        $(`${textContainer} textarea`).val($('#textDialog textarea').val()); 
        $('#textDialog .close').click();
    });
});
